import {
  FaTwitter,
  FaRegEnvelope,
  FaGithub,
  FaInstagram,
  FaSpotify,
  FaLastfmSquare,
} from "react-icons/fa";

import { Letterboxd } from "@icons-pack/react-simple-icons";

export default [
  {
    url: "mailto:hello@tylerbainbridge.com",
    Icon: FaRegEnvelope,
  },

  // {
  //   url: 'https://www.linkedin.com/in/tylerbainbridge',
  //   Icon: FaLinkedinIn,
  // },
  {
    url: "https://www.instagram.com/tubbytyinthetub/",
    Icon: FaInstagram,
  },
  {
    url: "https://twitter.com/tubbytyinthetub",
    Icon: FaTwitter,
  },
  {
    url: "https://www.last.fm/user/tylerbainbridge",
    Icon: FaLastfmSquare,
  },
  {
    url: "https://letterboxd.com/tylerbainbridge/",
    Icon: Letterboxd,
  },
  {
    url: "https://open.spotify.com/user/1286951915?si=74ZnWWCST_GcpCQMCqKGfw",
    Icon: FaSpotify,
  },
  {
    url: "https://github.com/tylerjbainbridge",
    Icon: FaGithub,
  },
];
